import request from '../request'


export function fetchList(data) {
    return request({
        url: '/user-service/employee/tag/employee/list',
        method: 'post',
        data
    })
}

export function fetchTagList(id) {
    return request({
        url: '/user-service/employee/tag/employee/tagList/' + id,
        method: 'get',
    })
}

export function remove(param) {
    return request({
        url: '/user-service/employee/tag/employee/relation/del/' + param.employeeId  + '/' + param.tagId ,
        method: 'post',
    })
}

export function add(data) {
    return request({
        url: '/user-service/employee/tag/employee/relation/add',
        method: 'post',
        data
    })
}