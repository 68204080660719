<template>
    <div>
      <Pane />
  
      <a-card class="container">
        <a-row>
          <a-col :span="22">
            <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
              <a-form-model-item>
                <a-input v-model="form.name" placeholder="姓名" style="width: 150px"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-select placeholder="性别" v-model="form.sex" style="width: 150px">
                  <a-select-option
                    v-for="item in sex"
                    :key="item.value"
                    :value="item.value"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select
                  show-search
                  :filter-option="$selectFilterOption"
                  placeholder="部门"
                  v-model="form.deptId"
                  style="width: 150px"
                >
                  <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-select
                  mode="multiple"
                  :filter-option="$selectFilterOption"
                  show-search
                  placeholder="员工标签"
                  v-model="form.tagIdList"
                  style="min-width: 150px;max-width: 500px;"
                >
                  <a-select-option
                    v-for="item in tagArr"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
              <a-form-model-item>
                <a-space>
                  <a-button @click="query" type="primary">查询</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
  
        <Padding />
  
        <a-table
          bordered
          :data-source="list"
          :loading="loading"
          @change="onChange"
          :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
          }"
        >
          <a-table-column title="姓名" data-index="name"></a-table-column>
          <a-table-column title="性别" data-index="sex">
            <template slot-scope="sex">
              <DataDictFinder dictType="sex" :dictValue="sex" />
            </template>
          </a-table-column>
          <a-table-column title="部门" data-index="deptName"></a-table-column>
          <a-table-column title="手机号" data-index="mobile"></a-table-column>
          <a-table-column title="标签">
            <template slot-scope="text">
              <div v-if="text.tagList.length > 5" :title="text.tagList.map(v=>v.name).join(',')">
                <span>{{ text.tagList[0].name }}, </span>
                <span>{{ text.tagList[1].name }}, </span>
                <span>{{ text.tagList[2].name }}, </span>
                <span>{{ text.tagList[3].name }}, </span>
                <span>{{ text.tagList[4].name }}</span>
                <span>......</span>
              </div>
              <div v-else>
                <span v-for="item, index in text.tagList" :key="item.id">{{ item.name }}<span v-if="index < text.tagList.length - 1">, </span></span>
              </div>
            </template>
          </a-table-column>
  
          <a-table-column title="操作" align="center" width="140px">
            <template slot-scope="text">
              <a-space>
                <a
                  href="#"
                  @click.prevent="$router.push('/human-resources/map/employee/detail?id=' + text.id)"
                >查看</a>
                <a
                  href="#"
                  @click.prevent="showDialog(text)"
                >标签管理</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>

      </a-card>
      <a-modal
        :visible="dialogShow"
        :title="'标签管理-' + name"
        @cancel="dialogShow = false"
        :footer="null"
        width="700px"
      >
      <a-table
          bordered
          :data-source="tagList"
        >
          <a-table-column title="序号" width="50px" align="center">
            <template slot-scope="text, row, index">
                {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column title="标签名称" data-index="name"></a-table-column>
          <a-table-column title="状态" align="center">
            <template slot-scope="text">
                <span v-if="text.status == 1">
                    正常
                </span>
                <span v-else>
                    禁用
                </span>
            </template>
          </a-table-column>
          <a-table-column title="获得方式" align="center">
            <template slot-scope="text">
                <span v-if="text">手动添加</span>
            </template>
          </a-table-column>
          <a-table-column title="操作" align="center" width="140px">
            <template slot-scope="text">
              <a-space>
                <a
                  href="#"
                  @click.prevent="deleteText(text)"
                >移除</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>

        <div style="margin-top: 20px;">
          <a-select
            mode="multiple"
            :filter-option="$selectFilterOption"
            show-search
            v-model="selectedTags"
            style="width: 300px"
          >
            <a-select-option
              v-for="item in tagArr"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
          <a
            style="margin: 0 10px;"
            href="#"
            @click.prevent="addTags()"
          >添加标签</a>
          <a
            href="#"
            @click.prevent="selectedTags = []"
          >清空</a>
        </div>
      </a-modal>
    </div>
  </template>
  
  
  <script>
  import organization from "@/mixins/organization";
  import { fetchList, remove, add, fetchTagList} from "@/api/human-resources/employeeTag";
  import { fetchList as fetchTags} from "@/api/human-resources/tag";
  import { mapGetters } from "vuex";
  export default {
    mixins: [organization],
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
      sex() {
        return this.findDataDict("sex");
      },
    },
    data() {
      return {
        form: {},
        selectedTags: [],
        tagArr: [],
        name: '',
        id: '',
        loading: false,
        current: 1,
        pageSize: 10,
        list: [],
        total: 0,
        dialogShow: false,
        tagList: []
      };
    },
  
    mounted() {
      this.getList();

      fetchTags({
          pageNum: 1,
          pageSize: 9999,
      })
      .then(res => {
        if (Array.isArray(res.list)) {
          this.tagArr = Object.freeze(res.list);
        }
      })
    },
  
    methods: {
      getList() {
        const { current, pageSize, form } = this;
        this.loading = true;
        fetchList({
          pageNum: current,
          pageSize: pageSize,
          ...form,
        })
          .then(res => {
            if (Array.isArray(res.list)) {
              this.list = Object.freeze(res.list);
              this.total = res.totalSize;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      onChange(pagination) {
        this.current = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList();
      },
  
      query() {
        this.current = 1;
        this.getList();
      },
      reset() {
        this.form = {};
        this.current = 1;
        this.getList();
      },
      showDialog(text) {
        this.dialogShow = true;
        this.name = text.name;
        this.id = text.id;
        this.getTagList();
      },
      getTagList() {
        fetchTagList(this.id)
        .then(res => {
          if (Array.isArray(res)) {
            this.tagList = Object.freeze(res);
          }
        })
      },
      deleteText(text) {
        const that = this;
        this.$confirm({
          title: "你确定要移除该标签吗？",
          onOk() {
            remove({
              employeeId: that.id,
              tagId: text.id
            }).then(() => {
              that.getTagList();
              that.getList();
            });
          }
        });
      },
      addTags() {
        add({
          employeeId: this.id,
          tagIdList: this.selectedTags
        }).then(() => {
          this.selectedTags = []
          this.getList();
          this.getTagList();
        });
      }
    }
  };
  </script>